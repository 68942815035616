import axios from 'axios';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';



export const Land = (props)=>{

    const {key} = useParams()
    let navigate = useNavigate();
    const [link,setLink] = useState('')
    const [isLoad,setIsLoad] = useState(true)
    const { t } = useTranslation();




    useEffect(()=>{
        axios.get(`https://professor-land.com/land/${key}`,{headers:{"key":"RkrWV8FcZAAEQhjRkrWVl2sAZUgalm"}}).then((response)=>{
            setLink(response.data.link)
            i18next.changeLanguage(response.data.geo.toLowerCase())
            setIsLoad(false)
        }).catch((err)=>{
            navigate('/')
        })
    },[key])


    
    
        
    const onGo = () => { 
        console.log(link)
        window.location.href = link
    }
    return(
    <div className="App">
        
        
          <div className="panel">
            { isLoad ? <div className='loading'> Loading ... </div> :
            <>
            <div className="avatar">
              <img src={'./static/Avatar_land.png'}></img>
              <span>Sergio</span>
            </div>
            <span onClick={onGo} className="btn">{t('button')}</span>
            </>
            }
          </div>
        
      </div>
    )
}