import './nonLand.css'


export const NonLand = () => {
    return(
        <div className="non_land">
            <div className="flex-center position-ref full-height">
                <div className="code">404</div>
                <div className="message" style={{padding: "10px"}}>Not Found</div>
            </div>
        </div>
    )
}