import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations. 
// We can localize to any language and any number of languages.
const resources = {
    it: {
        translation: {
          button: "Registrazione",
        },
    },
    es: {
        translation: {
          button: "Registro",
        },
    },
    de: {
        translation: {
            button: "Anmeldung",
        },
    },
    co: {
        translation: {
            button: "Registro",
        },
    },
    in:{
        translation: {
            button: "Registration",
        },
    },
    ww:{translation:{
      button:"Registration"
    }}
};
i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "es",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;