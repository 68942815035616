
import { Routes, Route, useParams } from 'react-router-dom';
import './App.css';
import { Land } from './components/Land';
import { NonLand } from './components/NonLand/NonParams';

function App() {


  


  return (
    <Routes>
        <Route path='/' element={<NonLand></NonLand>}></Route>
        <Route path='/:key' element={<Land></Land>}></Route>
    </Routes>
  );
}

export default App;
